module.exports = {
  baseURL:
    // process.env.NODE_ENV == "development" ? "http://192.168.0.141:8182" : "/",
    // baseURL:
    process.env.NODE_ENV == "development"
      ? "https://server.la6s.com"
      : "https://server.la6s.com",
  headers: {
    "content-type": "application/json;",
  },
  //是否启用加密，true启用、false 不启用
  STARTRSA: true,
};
