<template>
  <div>

    <!-- <van-skeleton v-if="isShow1" :row="3" /> -->
    <!-- <van-pull-refresh v-model="loading" @refresh="onRefresh" v-else> -->
    <div>
      <div class="top">
        <div class="ab">月分值查询</div>
      </div>
      <div style="position: fixed; top: 0; z-index: 999">
        <div style="position: relative">
          <div>
            <img style="width: 100%" src="@/static/img_year_bg.png" alt="" />
          </div>
          <div class="mon">
            <van-cell @click="showPopup" :style="{ width: '100%', height: '50%' }"><span class="time"
                style="color: #295cc9">{{
              currentDate1 ? currentDate1 : "2023年10月"
            }}</span>
              <van-icon name="arrow-down" color="#295CC9" style="margin-left: 0.1rem" /></van-cell>
          </div>
          <van-button @click="getData" type="primary" style="position: absolute; right: 40px;bottom: 0.5rem;" plain
            hairline size="small">查询</van-button>
        </div>
      </div>
      <div style="position: fixed;top: 2.5rem; z-index: 999;width: 100%;">
        <div
          style="width: 6.8rem; margin: 0 auto;display: flex;justify-content: space-between;height:0.5rem ;background-color: rgb(225, 235, 255);">
          <div class="s-title">日期</div>
          <div class="s-title">考勤</div>
          <div class="s-title">基分</div>
          <div class="s-title">奖扣</div>
          <div class="s-title">其他</div>
          <div class="s-title">分值</div>
        </div>
      </div>
      <div class="box" v-if="data.length > 0" style="margin-top: 0.4rem;">
        <div class="count" v-for="item in data" :key="item.id">
          <div class="wid_92">
            <div style=" display: flex;justify-content: space-between;">
              <div class="s-title">{{ item.workdate.substr(5) }}</div>
              <div class="s-title">{{ item.conditionName }}</div>
              <div class="s-title">{{
              userInfo.scoring != 1
                ? item.score.toFixed(2)
                : item.score.toFixed(0)
            }}
              </div>
              <div class="s-title"> {{
                userInfo.scoring != 1
                  ? item.awardscore.toFixed(2)
                  : item.awardscore.toFixed(0)
              }}</div>
              <div class="s-title">{{
                userInfo.scoring != 1
                  ? item.otheraward.toFixed(2)
                  : item.otheraward.toFixed(0)
              }}</div>
              <div class="price s-title">
                {{
                userInfo.scoring != 1
                  ? item.realscore.toFixed(2)
                  : item.realscore.toFixed(0)
              }}
              </div>
            </div>
            <div id="count_flx_xh" class="flx content">
              <div class="bor" v-if="item.isShow"></div>
              <div>
                <!-- <div class="flx6">
                  <div class="content_left">考勤</div>
                  <div class="content_right">{{ item.conditionName }}</div>
                </div> -->
                <!-- <div class="flx6">
                  <div class="content_left">基分</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.score.toFixed(2)
                : item.score.toFixed(0)
            }}
                  </div>
                </div> -->
                <!-- <div class="flx6">
                  <div class="content_left">班次</div>
                  <div class="content_right">{{ item.flight }}</div>
                </div> -->

                <div v-if="item.isShow" style="display: flex; flex-wrap: wrap;">
                  <div class="flx6" v-for="(item1, index) in titleData" :key="index">
                    <div class="content_left">{{ item1.name }}</div>
                    <!-- <div class="content_right">
                      {{
                        userInfo.scoring != 1
                          ? `item.exam${index + 1}`.toFixed(2)
                          : `item.exam${index + 1}`.toFixed(0)
                      }}
                    </div> -->
                    <div class="content_right" v-if="index == 0">
                      {{
              userInfo.scoring != 1
                ? item.exam1.toFixed(2)
                : item.exam1.toFixed(0)
            }}
                    </div>
                    <div class="content_right" v-if="index == 1">
                      {{
              userInfo.scoring != 1
                ? item.exam2.toFixed(2)
                : item.exam2.toFixed(0)
            }}
                    </div>
                    <div class="content_right" v-if="index == 2">
                      {{
              userInfo.scoring != 1
                ? item.exam3.toFixed(2)
                : item.exam3.toFixed(0)
            }}
                    </div>
                    <div class="content_right" v-if="index == 3">
                      {{
              userInfo.scoring != 1
                ? item.exam4.toFixed(2)
                : item.exam4.toFixed(0)
            }}
                    </div>
                    <div class="content_right" v-if="index == 4">
                      {{
              userInfo.scoring != 1
                ? item.exam5.toFixed(2)
                : item.exam5.toFixed(0)
            }}
                    </div>
                    <div class="content_right" v-if="index == 5">
                      {{
              userInfo.scoring != 1
                ? item.exam6.toFixed(2)
                : item.exam6.toFixed(0)
            }}
                    </div>
                  </div>
                </div>

                <!-- <div class="flx6" v-if="item.isShow">
                  <div class="content_left">奖扣</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.awardscore.toFixed(2)
                : item.awardscore.toFixed(0)
            }}
                  </div>
                </div> -->
                <!-- <div class="flx6" v-if="item.isShow">
                  <div class="content_left">其它</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.otheraward.toFixed(2)
                : item.otheraward.toFixed(0)
            }}
                  </div>
                </div> -->
                <div v-if="Option?.limit_patrol_mode?.status == 1"
                  style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                  <div class="flx6" v-if="item.isShow" style="width: 2rem;">
                    <div class="content_left">精益基分</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyscore.toFixed(2)
                : item.jyscore.toFixed(0)
            }}
                    </div>
                  </div>

                  <div class="flx6" v-if="item.isShow" style="width: 3rem;">
                    <div class="content_left">精益细则分</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyscoreExam.toFixed(2)
                : item.jyscoreExam.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益总分</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? (item.jyscore + item.jyscoreExam).toFixed(2)
                : (item.jyscore + item.jyscoreExam).toFixed(0)
            }}
                    </div>
                  </div>
                </div>

                <!-- <div class="flx6" v-if="item.isShow">
                  <div class="content_left">实得</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.realscore.toFixed(2)
                : item.realscore.toFixed(0)
                    }}
                  </div>
                </div> -->
              </div>
              <div class="img" v-if="item.comment == 1 || item.comment == -1">
                <img src="@/static/icon_pj_dy.png" v-if="item.comment == 1" alt="" />
                <img src="@/static/icon_pj_dy1.png" v-if="item.comment == -1" alt="" />
              </div>
            </div>
            <div class="bor"></div>
            <div style="text-align: center" @click="getIshow(item)">
              <van-icon v-if="item.isShow" name="arrow-up" color="#D9D9D9" style="margin: 0.26rem auto" />
              <van-icon v-else name="arrow-down" color="#D9D9D9" style="margin: 0.1rem auto" />
            </div>
          </div>
        </div>
        <div
          style="position: fixed;bottom: 0; z-index: 999;width: 100%;display: flex;background-color: rgb(225, 235, 255);">
          <div
            style="width: 6.8rem; margin: 0 auto;display: flex;justify-content: space-between;height:0.5rem ;min-height: 0.9rem;align-items: center;">
            <div class="s-title">合计</div>
            <div class="s-title"></div>
            <div class="s-title">{{ userInfo.scoring != 1
              ? scoreSum.toFixed(2)
              : scoreSum.toFixed(0) }}</div>
            <div class="s-title">{{ userInfo.scoring != 1
              ? awardscoreSum.toFixed(2)
              : awardscoreSum.toFixed(0) }}</div>
            <div class="s-title">{{ userInfo.scoring != 1
              ? otherawardSum.toFixed(2)
              : otherawardSum.toFixed(0) }}</div>
            <div class="s-title">{{ userInfo.scoring != 1
              ? realscoreSum.toFixed(2)
              : realscoreSum.toFixed(0) }}</div>
          </div>
        </div>
      </div>

      <van-empty class="box" v-else description="暂无数据" />
      <!-- </van-pull-refresh> -->

    </div>
    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker v-model="currentDate" value="{{currentDate}}" type="year-month" title="选择年月"
        @confirm="confirm" @cancel="cancel" />
    </van-popup>
  </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue";
// 全页面背景色配置
import { useRoute, useRouter } from "vue-router";
import { format } from "@/utils/index.js";
export default {
  setup() {
    const router = useRouter();
    const useRouteone = useRoute();
    const { proxy } = getCurrentInstance();
    const reactiveParams = reactive({
      currentDate: new Date(),
      currentDate1: format(new Date(), "yyyy年MM月"),
      isShow: false,
      show: false,
      data: [],
      titleData: [],
      isShow1: true,
      loading: false,
      userInfo: {},
      Option: JSON.parse(localStorage.getItem("Option"))
        ? JSON.parse(localStorage.getItem("Option"))
        : JSON.parse(sessionStorage.getItem("Option")),
      scoreSum: 0,
      awardscoreSum: 0,
      otherawardSum: 0,
      realscoreSum: 0,
    });

    const showPopup = () => {
      reactiveParams.show = true;
    };
    const onRefresh = () => {
      // reactiveParams.loading = true;
      getData();
    };
    const getData = async () => {
      let userInfo = useRouteone.query.userInfo
        ? JSON.parse(useRouteone.query.userInfo)
        : sessionStorage.getItem("userInfo")
          ? JSON.parse(sessionStorage.getItem("userInfo"))
          : JSON.parse(localStorage.getItem("userInfo"));
      reactiveParams.userInfo = userInfo;

      let pamas = {
        companyId: userInfo.companyId,
        customerGroupId: userInfo.groupId,
        workMonth: reactiveParams.currentDate,
        customerId: userInfo.id,
        organizeId: userInfo.organizeId,
      };
      reactiveParams.data = [];
      await proxy.$Api
        .get(proxy.$scor.laExamTypesList, { order: "sort.asc", Grade: 1 })
        .then((res) => {
          console.log(res);
          let data = res.data.data;
          reactiveParams.titleData = data;
        });
      await proxy.$Api.get(proxy.$scor.getAllByMonth, pamas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          reactiveParams.loading = false;

          let data = res.data.data;

          reactiveParams.isShow1 = false;
          // scoreSum
          if (data) {
            for (let i = 0; i < data.length; i++) {
              data[i].isShow = false;
              reactiveParams.data.push(data[i]);
            }
          }
          reactiveParams.scoreSum = 0;
          reactiveParams.awardscoreSum = 0
          reactiveParams.otherawardSum = 0
          reactiveParams.realscoreSum = 0
          reactiveParams.data.forEach((item) => {
            reactiveParams.scoreSum += item.score;
            reactiveParams.awardscoreSum += item.awardscore;
            reactiveParams.otherawardSum += item.otheraward;
            reactiveParams.realscoreSum += item.realscore;
          })
          console.log(data, 'data');
        } else {
          reactiveParams.isShow1 = false;
        }
      });
    };
    const confirm = async (e) => {
      reactiveParams.currentDate1 = format(e, "yyyy年MM月");
      reactiveParams.currentDate = format(e, "yyyy-MM");
      console.log(reactiveParams.currentDate);
      await cancel();
      await getData();
    };
    const cancel = () => {
      reactiveParams.show = false;
    };
    const getIshow = (item) => {
      item.isShow = item.isShow ? false : true;
      console.log(item.isShow);
      console.log(item);
      var a = document.getElementById('count_flx_xh')
      a.style = 'display:BLock';
    };
    onMounted(() => {
      getData();
    });
    // 开始触发事件
    return {
      ...toRefs(reactiveParams),
      router,
      useRouteone,

      showPopup,
      confirm,
      cancel,
      getIshow,
      getData,
      onRefresh,
    };
  },
};
</script>
<style lang="scss" scoped>
.top {
  // height: 3.4rem;
  background: #295cc9;
  position: relative;
  // display: flex;
  // align-items: end;
  // vertical-align: bottom;
}

.ab {
  padding-left: 0.4rem;
  font-size: 0.32rem;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  bottom: 0;
}

.img {
  width: 0.98rem;
  height: 0.9rem;
}

.img img {
  width: 100%;
  height: 100%;
}

.menu {
  width: 5.4rem;
  // margin: -70px auto;
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}

.menu div div:last-child {
  font-size: 0.27rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000000;
}

.time {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #1a1a1a;
}

.price {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cc0000;
}

.content {
  margin-top: 0.14rem;
}

.content div .flx6 {
  margin-top: 0.2rem;
  margin-left: 0.15rem;
  width: 2rem;
}

.content_left {

  font-size: 0.28rem;
  font-weight: 400;
  color: #8c8c8c;


}

.content_right {
  // width: 1.4rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #1a1a1a;

  margin-left: 0.64rem;
  // border: 1px solid blue;
}

.van-cell {
  background: transparent !important;
}

.mon {
  position: absolute;
  bottom: 0.32rem;
  left: 0.64rem;
}

.count {
  width: 6.8rem;
  // min-height: 3rem;
  margin: 0.2rem auto;
  background: white;
  border-radius: 0.1rem;
  font-size: 0.28rem;
  // border: 1px solid red;
}

.wid_92 {
  width: 100%;
  margin: 0 auto;
  padding-top: 0.18rem;
}

.bor {
  margin-top: 0.05rem;
  width: 100%;
  border-bottom: 0.02rem solid #f2f2f2;
}

#app {
  background: #f4f4f4;
}

.s-title {
  text-align: center;
  flex: 1;
}
</style>
