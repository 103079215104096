export default {
    /**
     * 接口名文件文档
     * 例:
     * // 获取图形验证码
     * captchaImage:'captchaImage',
     */
    // 日接口
    laTotalDays: "/api/v1/laTotalDays/list",
    // 月接口
    getAllByMonth: "/api/v1/laDaysCustomers/getAllByMonth",
    // 年接口
    getAllByYear: "/api/v1/laDaysCustomers/getAllByYear",
    laExamTypesList: '/api/v1/laExamTypes/list',

};
